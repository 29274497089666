<template>
	<main>
		<slot name="table1"></slot>
		<slot name="table2"></slot>
	</main>
</template>

<script>
export default {
  name: 'NotifyTable'
}
</script>

<style scoped>
</style>
